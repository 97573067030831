import "./TopMenu.css";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import FoodIcon from "../../assets/imgs/food-icon.svg";
import FoodSelectedIcon from "../../assets/imgs/food-selected-icon.svg";
import DashboardIcon from "../../assets/imgs/dashboard-icon.svg";
import DashboardSelectedIcon from "../../assets/imgs/dashboard-selected-icon.svg";
import TextLogo from "../../assets/imgs/text-logo.svg";
import NotificationIcon from "../../assets/imgs/notification.svg";

import loginService from "../../services/login-service";
import { Dropdown } from "react-bootstrap";
import notificationsService from "../../services/notifications-service";
import { MoonLoader } from "react-spinners";

const ADMINISTRATOR_ROLE = "ROLE_ADMIN";
const NUTRITIONIST_ROLE = "ROLE_NUTRITIONIST";

const NutritionistMenuSection = () => {
  const nutritionistMenuItems = [
    {
      title: "Dashboard",
      screen: "home",
    },
    {
      title: "Consultas",
      screen: "appointments",
    },
    {
      title: "Pacientes",
      screen: "patients",
    },
  ];

  const currentPage = window.location.pathname.split("/")[1];

  return (
    <div className="top-menu__nutritionist__items">
      {nutritionistMenuItems.map((item, index) => (
        <a
          key={index}
          href="javascript:;"
          className={`top-menu__nutritionist__item ${currentPage === item.screen && "top-menu__nutritionist__item--selected"}`}
          onClick={() => { window.location.href = `/${item.screen}` }}
        >
          {item.title}
        </a>
      ))}
    </div>
  )
};

const NamingAvatar = ({ userName }) => {
  const names = userName?.split(" ");
  if (!names) {
    return <></>
  }
  const initials = names?.length > 1 ? names[0].charAt(0) + names[names.length - 1].charAt(0) : names[0].charAt(0);

  return (
    <a href="javascript:;" className="top-menu__user__icon__initials">
      {initials}
    </a>
  )
}

const UserAvatarSection = ({ user }) => {
  const [openOptionsDropdown, setOpenOptionsDropdown] = useState(false);

  const additionalOptions = [
    {
      title: "Meu perfil",
      onClick: () => {
        window.location.href = "/profile";
      },
    },
    {
      title: "Sair da conta",
      onClick: () => {
        loginService.logout();
        window.location.href = "/login";
      },
    }
  ];

  return (
    <div className="top-menu__user">
      {user?.photo_url ? (
        <a className="top-menu__user__icon" href="javascript:;" onClick={() => {
          setOpenOptionsDropdown(prevState => !prevState);
        }}>
          <img src={user?.photo_url} alt="User Icon" />
        </a>
      ) : (
        <NamingAvatar userName={user?.name} />
      )}
      <Dropdown
        show={openOptionsDropdown}
        onToggle={() => setOpenOptionsDropdown(prevState => !prevState)}
        className="top-menu__additional-options"
      >
        <Dropdown.Menu bsPrefix="dropdown-menu top-menu__additional-options__dropdown">
          {additionalOptions?.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={item.onClick}
              className={`top-menu__additional-options__dropdown__item ${item.className || ''}`}
            >
              {item.title}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
};

const notificationTextByCode = {
  APPOINTMENT_REFUSED: "recusou o agendamento para o dia 12/09.",
  APPOINTMENT_CONFIRMED: "confirmou o agendamento para o dia 07/09.",
  PATIENT_CONNECTION_ACCEPTED: "aceitou o acompanhamento nutricional.",
  PATIENT_CONNECTION_REFUSED: "recusou o acompanhamento nutricional."
}

const NotificationsSection = () => {
  const [notificationsResponse, setNotificationsResponse] = useState();
  const [notifications, setNotifications] = useState([]);
  const [notificationIsOpened, setNotificationIsOpened] = useState(false);
  const [closeNotification, setCloseNotification] = useState(false);
  const [selectedOption, setSelectedOption] = useState("ALL");
  const [notificationsLoading, setNotificationsLoading] = useState(false);

  const notificationRef = useRef(null);
  const notificationBellRef = useRef(null);

  const fetchNotifications = useCallback(async (page = 0, status) => {
    console.log("Fetching notifications");

    setNotificationsLoading(page !== 99999);

    const currentUser = await loginService.getCurrentUser();
    try {
      notificationsService.retrieveNotifications(currentUser.id, status, page, 20).then((response) => {
        const newNotifications = response.content.map((notification) => {
          const metadata = new Map();
          for (let data of notification.metadata) {
            metadata.set(data.key, data.value);
          }

          return {
            ...notification,
            patient_photo_url: metadata.get('patient_photo_url'),
            patient_name: metadata.get('patient_name'),
          }
        });

        setNotificationsResponse(response);
        setNotifications(() => {
          setNotificationsLoading(false);
          console.log("Notifications fetched", newNotifications);
          return newNotifications;
        });
      });
    } catch {
      console.error("Error fetching notifications");
    }
  }, [setNotificationsResponse, setNotifications]);

  const formatNotificationSinceDate = (notification) => {
    const notificationDateTime = new Date(notification.created_at);
    const now = new Date();

    const diff = now - notificationDateTime;
    const diffInHours = diff / (1000 * 60 * 60);
    const diffInDays = diffInHours / 24;
    const diffInMonths = diffInDays / 30;

    if (diffInHours < 1) {
      return `Há menos de uma hora atrás`;
    }

    if (diffInDays < 1) {
      return `Há ${Math.floor(diffInHours)} horas atrás`;
    }

    if (diffInMonths < 1) {
      return `Há ${Math.floor(diffInDays)} dias atrás`;
    }

    return `Há ${Math.floor(diffInMonths)} meses atrás`;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!notificationIsOpened) {
        return
      }

      if (notificationRef.current && !notificationBellRef.current.contains(event.target) && !notificationRef.current.contains(event.target)) {
        setSelectedOption("ALL");
        setCloseNotification(true);
        setTimeout(() => {
          setNotificationIsOpened(false);
          setCloseNotification(false);
        }, 100);
        fetchNotifications(99999);
      }
    }

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [notificationIsOpened]);

  useEffect(() => {
    if (notificationsResponse) {
      return;
    }

    Promise.resolve(fetchNotifications(999999));
  }, []);

  return (
    <>
      <a 
        href="javascript:;"
        className={`top-menu__notifications ${notificationIsOpened ? 'top-menu__notifications--opened' : ''}`}
        onClick={() => {
          setNotificationIsOpened(prevState => {
            if (closeNotification) {
              return prevState;
            }

            if (prevState) {
              setCloseNotification(true);
              setTimeout(() => {
                setNotificationIsOpened(false);
                setCloseNotification(false);
              }, 100);
              return prevState;
            }
            return !prevState;
          })

          if (!notificationIsOpened) {
            fetchNotifications();
          } else {
            fetchNotifications(99999);
          }
        }}
        ref={notificationBellRef}
      >
        <img src={NotificationIcon} alt="Notification Icon" />
        {notificationsResponse?.total_unread > 0 && (
          <div className="top-menu__notifications__counter">
            <span>{notificationsResponse?.total_unread > 99 ? 99 : notificationsResponse?.total_unread}</span>
          </div>
        )}
      </a>
      {notificationIsOpened && (
        <div className={`top-menu__notifications__box ${closeNotification ? 'top-menu__notifications__box--hidden' : ''}`} ref={notificationRef}>
          <div className="top-menu__notifications__box__header">
            <p className="top-menu__notifications__box__title">Notificações</p>
            <div className="top-menu__notifications__box__options">
              <a
                href="javascript:;"
                className={`top-menu__notifications__box__option ${selectedOption === 'ALL' ? 'top-menu__notifications__box__option--selected' : ''}`}
                onClick={() => {
                  setSelectedOption('ALL');
                  fetchNotifications();
                }}
              >
                <p>Tudo</p>
              </a>
              <a 
                href="javascript:;" 
                className={`top-menu__notifications__box__option ${selectedOption === 'UNREAD' ? 'top-menu__notifications__box__option--selected' : ''}`}
                onClick={() => {
                  setSelectedOption('UNREAD');
                  fetchNotifications(0, 'UNREAD');
                }}
              >
                <p>Não lidas</p>
              </a>
            </div>
          </div>
          <div className="top-menu__notifications__box__items">
            {!notificationsLoading && notifications.map((notification, index) => (
              <div key={index} className="top-menu__notifications__box__item">
                <div className="top-menu__notifications__box__item__avatar">
                  <img src={notification.patient_photo_url} alt="User Avatar" />
                </div>
                <div className="top-menu__notifications__box__item__content">
                  <p>
                    <span>{notification.patient_name}</span> {notificationTextByCode[notification.code]}
                  </p>
                  <p className="top-menu__notifications__box__item__time">{formatNotificationSinceDate(notification)}</p>
                </div>
                {notification.status === 'UNREAD' && <div className="top-menu__notifications__box__item__unread-circle" />}
              </div>
            ))}
            {notificationsLoading && (
              <div className="notification-loader">
                <MoonLoader
                  color={"#ef768a"}
                  loading={notificationsLoading}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}

const AdminScreensSection = () => {
  const currentPage = window.location.pathname.split("/")[1];

  return (
    <div className="top-menu__items">
      <a href="/foodstuffs">
        <div
          className={`top-menu__items__item ${currentPage === "create-foodstuff" ||
            currentPage.startsWith("foodstuffs") && "top-menu__items__item--chosen"}`}
        >
          <div className="top-menu__items__item__icon">
            {currentPage === "create-foodstuff" || currentPage.startsWith("foodstuffs") ? (
              <img src={FoodSelectedIcon} alt="Dashboard selected icon" />
            ) : (
              <img src={FoodIcon} alt="Food icon" />
            )}
          </div>
        </div>
      </a>
      <a href="javascript:;" onClick={() => { window.location.href = "/home" }}>
        <div
          className={`top-menu__items__item ${currentPage === "home" && "top-menu__items__item--chosen"}`}
        >
          <div className="top-menu__items__item__icon">
            {currentPage === "home" ? (
              <img src={DashboardSelectedIcon} alt="Dashboard selected icon" />
            ) : (
              <img src={DashboardIcon} alt="Dashboard icon" />
            )}
          </div>
        </div>
      </a>
    </div>
  )
}

const NutritionistMenu = ({ currentUser }) => {
  console.log('mounting nutritionist menu');
  return (
    <div className="top-menu__shadow">
      <div className="top-menu">
        <div className="top-menu__left">
          <a className="top-menu__logo" href="/home">
            <img src={TextLogo} alt="Text Logo" />
          </a>
          <NutritionistMenuSection />
        </div>
        <div className="top-menu__right">
          <NotificationsSection />
          <UserAvatarSection user={currentUser} />
        </div>
      </div>
    </div>
  );
}

const AdministratorMenu = ({ currentUser } ) => {
  return (
    <div className="top-menu">
      <div className="top-menu__left">
        <a className="top-menu__logo" href="/home">
          <img src={TextLogo} alt="Text Logo" />
        </a>
      </div>
      <div className="top-menu__right">
        <AdminScreensSection />
        <UserAvatarSection user={currentUser} />
      </div>
    </div>
  )
}

const TopMenu = () => {
  const [user, setUser] = useState();
  const [mainRole, setMainRole] = useState();
  const [currentUser, setCurrentUser] = useState();

  const fetchCurrentUser = useCallback(async () => {
    const user = await loginService.getCurrentUser();
    setCurrentUser(user);

    const mappedRoles = new Map();
    
    for (let role of user?.roles) {
      mappedRoles.set(role.name, role);
    };

    if (mappedRoles.has("ROLE_NUTRITIONIST")) {
      setMainRole("ROLE_NUTRITIONIST");
    } else if (mappedRoles.has("ROLE_ADMIN")) {
      setMainRole("ROLE_ADMIN");
    };

    setUser(user);
  }, []);



  useEffect(() => {
    if (currentUser) {
      return;
    }

    Promise.resolve(fetchCurrentUser());
  }, [fetchCurrentUser]);

  return (
    <div className="top-menu__shadow">
      {user && <>
        {mainRole === NUTRITIONIST_ROLE && <NutritionistMenu currentUser={user} />}
        {mainRole === ADMINISTRATOR_ROLE && <AdministratorMenu currentUser={user} />}
      </>}
    </div>
  );
};

export default TopMenu;
