import "./Patients.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import loginService from "../../services/login-service";
import { GlobalContext } from "../GlobalContext";
import DefaultFoodstuffIcon from "../../assets/imgs/default-foodstuff-icon.svg";
import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";
import GreenDotIcon from "../../assets/imgs/greendot.svg";
import NoteTextIcon from "../../assets/imgs/note-text-icon.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";
import NumericPagination from "../../components/NumericPagination/NumericPagination";
import DefaultModal from "../../components/DefaultModal/DefaultModal";

import TextInput from "../../components/TextInput/TextInput";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import ErrorModal from "../../components/ErrorModal/ErrorModal";
import connectionRequestService from "../../services/connection-request-service";
import nutritionistService from "../../services/nutritionist-service";
import NotificationBar from "../../components/NotificationBar/NotificationBar";
import { formatToDayMonthYearDate } from "../../utils/date-converter";

const emailIsValid = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;
  return emailRegex.test(email);
}

const Patients = () => {
  const [currentUser, setCurrentUser] = useState();
  const [usersResponse, setUsersResponse] = useState({});
  const [searchedUserName, setSearchedUserName] = useState("");
  const [users, setUsers] = useState([]);
  const [currentUsersPage, setCurrentUsersPage] = useState(1);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [showInviteErrorModal, setShowInviteErrorModal] = useState(false);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const fetchUsers = useCallback(async (nutritionistId, name, page) => {
    if (!isLoading) {
      setIsLoading(true);
    }
    const usersResponse = await nutritionistService.getPatients(nutritionistId, page ?? currentUsersPage, 8, name);
    setUsersResponse(usersResponse);
    setUsers(usersResponse.content);
    setCurrentUsersPage(page + 1);
    setIsLoading(false);
  }, []);

  const formatActivityFactor = (activityFactor) => {
    const formattedNames = {
      SEDENTARY: "Sedentário",
      LIGHTLY_ACTIVE: "Pouco ativo",
      MODERATELY_ACTIVE: "Moderadamente ativo",
      VERY_ACTIVE: "Muito ativo",
      EXTRA_ACTIVE: "Super ativo",
    }

    return formattedNames[activityFactor];
  }

  const sendInvite = async () => {
    try {
      setIsLoading(true);
      await connectionRequestService.sendInvite(currentUser.id, inviteEmail);
      setShowNotificationBar(true);
    } catch (error) {
      setShowInviteErrorModal(true);
      console.log("Erro ao enviar convite", error);
    } finally {
      setIsLoading(false);
      setShowInviteModal(false);
    }
  }

  const handleCloseInviteModal = () => {
    setShowInviteModal(false);
    setShowInviteErrorModal(false);
    setInviteEmail("");
  }

  useEffect(() => {
    document.title = "Dashboard";
    setSelectedScreen("home");

    if (!isLoading) {
      setIsLoading(true);
    };
    async function fetchCurrentUser() {
      const userFound = await loginService.getCurrentUser();
      setCurrentUser(userFound);
      return userFound;
    }

    async function fetchData() {
      const currUser = await fetchCurrentUser();
      await fetchUsers(currUser.id, "", 0);
      setIsLoading(false);
    }

    fetchData();
  }, []);

  return (
    <>
      <div className="nutritionist">
        <div className="nutritionist__content">
          <div className="nutritionist__content__header">
            <div className="nutritionist__content__header__title">
              {<h1>{usersResponse.total_elements || 0} Pacientes</h1>}
            </div>
            <div className="nutritionist__content__header__invite">
              {usersResponse?.total_elements > 0 && (
                <DefaultButton
                  title="Convidar paciente"
                  type="primary"
                  onClick={() => setShowInviteModal(true)}
                />
              )}
            </div>
          </div>
          {usersResponse?.total_elements > 0 && (
            <div className="foodstuffs__list">
              <div className="nutritionist__users__header">
                <div className="nutritionist__users__header__name">Nome</div>
                <div className="nutritionist__users__header__birthdate">Data de nascimento</div>
                <div className="nutritionist__users__header__height">Plano alimentar</div>
                <div className="nutritionist__users__header__weight">Nível de atividade física</div>
                <div className="nutritionist__users__header__actions" />
              </div>
              <div className="nutritionist__users">
                {users.map((user) => (
                  <div className="nutritionist__users__item">
                    <div className="nutritionist__users__item__name">
                      <div className="nutritionist__users__item__image">
                        <img src={user.photo_url ?? DefaultFoodstuffIcon} alt="Banana icon" />
                      </div>
                      <p>{user.name}</p>
                    </div>
                    <div className={`nutritionist__users__item__birthdate ${!user.birth_date && 'nutritionist__users__item--not-appliable'}`}>
                      {user?.birth_date ? formatToDayMonthYearDate(user.birth_date) : 'Não informado'}
                    </div>
                    <div className="nutritionist__users__item__meal-plan">
                      <div className="nutritionist__users__item__meal-plan-item">
                        <div className="nutritionist__users__item__meal-plan-dot">
                          <img src={GreenDotIcon} alt="Green Dot" />
                        </div>
                        <div className="nutritionist__users__item__meal-plan-text">
                          <p>Ativo</p>
                        </div>
                      </div>
                    </div>
                    <div className={`nutritionist__users__item__weight ${!user?.physical_activity_factor && 'nutritionist__users__item--not-appliable'}`}>
                      {user?.physical_activity_factor ? formatActivityFactor(user?.physical_activity_factor) : 'Não informado'}
                    </div>
                    <div className="nutritionist__users__item__actions">
                      <div className="home__nutritionist__users__item__actions__edit">
                        <Button
                          onClick={() =>
                            (window.location.href = `/patients/${user.id}`)
                          }
                        >
                          <img src={ChevronRightIcon} alt="Right Arrow" />
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="nutritionist__users__footer">
                {currentUsersPage && currentUsersPage <= usersResponse.total_pages && (
                  <NumericPagination
                    currentPage={currentUsersPage === 0 ? 1 : Number(currentUsersPage)}
                    totalPages={usersResponse.total_pages}
                    onSelectPage={(page) => {
                      fetchUsers(searchedUserName, page);
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {usersResponse?.total_elements === 0 && (
            <div className="patients__empty">
              <div className="patients__empty__icon">
                <img src={NoteTextIcon} alt="Note Text Icon" />
              </div>
              <div className="patients__empty__title">
                <h2>Bem-vindo(a) à Lorie!</h2>
              </div>
              <div className="patients__empty__description">
                <p>Aqui você poderá gerenciar seus pacientes e acompanhar o progresso nutricional deles. Comece convidando seus primeiros pacientes.</p>
              </div>
              <div className="patients__empty__button">
                <DefaultButton
                  title="Convidar paciente"
                  type="primary"
                  onClick={() => setShowInviteModal(true)}
                  size="small"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <DefaultModal
        title="Convidar paciente"
        showModal={showInviteModal}
        onClose={() => setShowInviteModal(false)}
      >
        <div className="invite-modal">
          <div className="invite-modal__form">
            <TextInput
              label="E-mail"
              type="email"
              placeholder="Digite o e-mail do paciente"
              value={inviteEmail}
              onChangeText={(text) => setInviteEmail(text)}
              maxLength={120}
            />
          </div>
          <div className="invite-modal__actions">
            <div className="invite-modal__button">
              <DefaultButton
                title="Voltar"
                type="secondary"
                onClick={() => setShowInviteModal(false)}
                size="small"
              />
            </div>
            <div className="invite-modal__button">
              <DefaultButton
                title="Enviar convite"
                type="primary"
                onClick={() => sendInvite()}
                size="small"
                disabled={!emailIsValid(inviteEmail)}
              />
            </div>
          </div>
        </div>
      </DefaultModal>
      <ErrorModal
        title="Ops! Algo deu errado"
        description="Houve um problema ao enviar o convite. Tente novamente mais tarde."
        show={showInviteErrorModal}
        confirmTitle="Tentar novamente"
        backTitle="Voltar"
        onConfirm={sendInvite}
        onExit={handleCloseInviteModal}
        onBack={handleCloseInviteModal}
      />
      <NotificationBar
        title={`Convite enviado com sucesso para o e-mail ${inviteEmail}!`}
        durationInSeconds={5}
        show={showNotificationBar}
        setShow={setShowNotificationBar}
        onClose={() => setInviteEmail("")}
      />
    </>
  );
};

export default Patients;

Patients.propTypes = {};
