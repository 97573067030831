import "./ConnectionRequests.css";
import "bootstrap/dist/css/bootstrap.min.css";

import TextLogo from "../../assets/imgs/text-logo.svg";
import SuccessIcon from "../../assets/imgs/success-icon.svg";
import ErrorIcon from "../../assets/imgs/error-icon.svg";

import React, { useCallback, useContext, useEffect, useState } from "react";
import DefaultButton from "../../components/DefaultButton/DefaultButton";
import { useParams } from "react-router-dom";
import connectionRequestService from "../../services/connection-request-service";
import { GlobalContext } from "../GlobalContext";

const AcceptanceSuccess = () => {
  return (
    <div className="default-step">
      <div className="registration-success__icon">
        <img src={SuccessIcon} alt="Success" />
      </div>
      <h1 className="default-step__title registration-success__title">Solicitação aceita com sucesso!</h1>
      <p className="default-step__title__subtitle">A solicitação de conexão com o nutricionista foi aceita com sucesso. Agora o seu nutricionista poderá te ajudar com sua alimentação mais de perto!</p>
      <div className="default-step__divisor" />
      <div className="next-steps">
        <p className="next-steps__title">Próximos passos:</p>
        <p className="next-steps__subtitle">Acesse sua conta através do aplicativo para aproveitar as novas funcionalidades.</p>
        <DefaultButton
          title="Acessar conta"
          onClick={() => {
            window.location.assign("/login");
          }}
        />
      </div>
    </div>
  )
}

const AcceptanceError = () => {
  return (
    <div className="default-step">
      <div className="registration-success__icon">
        <img src={ErrorIcon} alt="Success" />
      </div>
      <h1 className="default-step__title registration-success__title">Erro ao aceitar solicitação</h1>
      <p className="default-step__title__subtitle">Verifique se o código está correto ou se a solicitação já foi aceita anteriormente.</p>
      <div className="default-step__actions">
        <DefaultButton
          title="Acessar aplicativo"
          onClick={() => {
            window.location.assign("/login");
          }}
        />
      </div>
    </div>
  )
}

const ConnectionRequests = () => {
  const [acceptanceCode, setAcceptanceCode] = useState(useParams().id);
  const [codeWasAccepted, setCodeWasAccepted] = useState();

  const { setIsLoading } = useContext(GlobalContext);

  const acceptConnectionRequest = useCallback(async (acceptanceCode) => {

    setIsLoading(true);
    try {
      await connectionRequestService.acceptConnectionRequest(acceptanceCode);
      setCodeWasAccepted(true);
    } catch (error) {
      console.error(error);
      setCodeWasAccepted(false);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (acceptanceCode) {
      acceptConnectionRequest(acceptanceCode);
    }
  }, [acceptConnectionRequest]);

  return (
    <div className="registration">
      <div className="registration__header">
        <a href="/" className="registration__header__logo">
          <img src={TextLogo} alt="Logo" />
        </a>
        <div className="registration__header__button">
          <DefaultButton
            title="Conheça mais"
            type="quaternary"
            onClick={() => {
              window.location.assign("/");
            }}
          />
        </div>
      </div>
      <div className="registration__content">
        {codeWasAccepted === true && <AcceptanceSuccess />}
        {codeWasAccepted === false && <AcceptanceError />}
      </div>
    </div>
  )
};

export default ConnectionRequests;

ConnectionRequests.propTypes = {};
