import axios from "axios";
import { getAPIUrl } from "../config";

const api = axios.create({
  baseURL: getAPIUrl(),
});

const notificationsService = {
  async retrieveNotifications(receiverId, status, page, size) {
    const token = localStorage.getItem("token");

    const response = await api.get(
      `/v1/notifications?receiver_id=${receiverId}&page=${page}&size=${size}${status ? `&status=${status}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    return response.data;
  }
};

export default notificationsService;