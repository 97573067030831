// import "./Navigator.css";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "../login/Login";
import Home from "../home/Home";
import Landing from "../landing/Landing";
import UpdateFoodstuff from "../update-foodstuff/UpdateFoodstuff";
import CreateFoodstuff from "../create-foodstuff/CreateFoodstuff";
import Foodstuffs from "../foodstuffs/Foodstuffs";
import loginService from "../../services/login-service";
import { GlobalContext } from "../GlobalContext";
import { BounceLoader } from "react-spinners";
import Privacy from "../privacy/Privacy";
import Patient from "../patient/Patient";
import NutritionistRegistration from "../nutritionist-registration/NutritionistRegistration";
import TopMenu from "../../components/TopMenu/TopMenu";
import NutritionistDashboard from "../nutritionist-dashboard/NutritionistDashboard";
import PatientProgress from "../patient-progress/PatientProgress";
import Appointments from "../appointments/Appointments";
import Appointment from "../appointment/Appointment";
import Patients from "../patients/Patients";
import NutritionistProfile from "../nutritionist-profile/NutritionistProfile";
import ConnectionRequests from "../connection-requests/ConnectionRequests";

const MainNavigator = () => {
  const currentPageFromPath = window.location.pathname.split("/")[1];
  const [currentUser, setCurrentUser] = useState();
  const { isLoading } = useContext(GlobalContext);

  const publicScreens = ["login", "privacy-policy", "nutritionist-registration"];

  const screenRequiresLogin = !publicScreens.includes(currentPageFromPath) && currentPageFromPath && currentPageFromPath !== "" && currentPageFromPath !== "privacy-policy" && currentPageFromPath !== "connection-requests";

  useEffect(() => {
    async function fetchCurrentUser() {
      try {
        const loggedInUser = await loginService.getCurrentUser();

        if (!loggedInUser && screenRequiresLogin) {
          window.location.assign("/login");
        }

        if (loggedInUser && currentPageFromPath === "login") {
          window.location.assign("/home");
        }

        setCurrentUser(loggedInUser);
      } catch (error) {
        console.error(error);
      }
    }

    if (!currentUser) {
      Promise.resolve(fetchCurrentUser());
    }
  }, []);

  const userIsNutritionist = currentUser?.roles?.map(role => role.name).includes("ROLE_NUTRITIONIST");

  return (
    <>
      {isLoading && (
        <div className="loader">
          <BounceLoader
            color={"#ef768a"}
            loading={isLoading}
            size={80}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      {currentUser && (
        <>
          {screenRequiresLogin ? (
            <Router>
              <div className="app-content">
                <TopMenu />
                <Routes>
                  <Route exact path="/patients" element={<Patients />} />
                  <Route exact path="/" element={<Landing />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  {userIsNutritionist && (
                    <Route exact path="/patients" element={<NutritionistDashboard />} />
                  )}
                  {!userIsNutritionist && (
                    <Route exact path="/dashboard" element={<Home />} />
                  )}
                  {userIsNutritionist && (
                    <Route exact path="/appointments" element={<Appointments />} />
                  )}
                  <Route exact path="/foodstuffs" element={<Foodstuffs />} />
                  <Route
                    exact
                    path="/foodstuffs/:id/editing"
                    element={<UpdateFoodstuff />}
                  />
                  <Route
                    exact
                    path="/patients/:id"
                    element={<Patient />}
                  />
                  {userIsNutritionist && (
                    <Route
                      exact
                      path="/appointments/:id"
                      element={<Appointment />}
                    />
                  )}
                  <Route
                    exact
                    path="/create-foodstuff"
                    element={<CreateFoodstuff />}
                  />
                  {!userIsNutritionist && (
                    <Route
                      path="*"
                      element={<Home />}
                    />
                  )}
                  {userIsNutritionist && (
                    <Route
                      path="*"
                      element={<NutritionistDashboard />}
                    />
                  )}
                  {userIsNutritionist && (
                    <Route
                      path="/patients/:id/progress"
                      element={<PatientProgress />}
                    />
                  )}
                  {userIsNutritionist && (
                    <Route
                      path="/profile"
                      element={<NutritionistProfile />}
                    />
                  )}
                </Routes>
              </div>
            </Router>
          ) : (
            <Router>
              <div>
                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route exact path="/privacy-policy" element={<Privacy />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/nutritionist-registration" element={<NutritionistRegistration />} />
                  <Route exact path="/connection-requests/acceptance-codes/:id" element={<ConnectionRequests />} />
                </Routes>
              </div>
            </Router>
          )}
        </>
      )}
      {!currentUser && (
        <Router>
          <div>
            <Routes>
              <Route exact path="/" element={<Landing />} />
              <Route exact path="/privacy-policy" element={<Privacy />} />
              <Route exact path="/login" element={<Login />} />
              <Route exact path="/nutritionist-registration" element={<NutritionistRegistration />} />
            </Routes>
          </div>
        </Router>
      )}
    </>
  )
};

export default MainNavigator;
