import "./Appointments.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import { GlobalContext } from "../GlobalContext";

import ChevronRightIcon from "../../assets/imgs/chevron-right-icon.svg";
import CalendarIcon from "../../assets/imgs/calendar-icon.svg";
import NoteTextIcon from "../../assets/imgs/note-text-icon.svg";

import DefaultButton from "../../components/DefaultButton/DefaultButton";

import LightMenu from "../../components/LightMenu/LightMenu";
import StatusTag from "../../components/StatusTag/StatusTag";
import { ColumnHeader, ColumnValue, DefaultTableFooter } from "../../components/DefaultTable/DefaultTable";

import loginService from "../../services/login-service";
import appointmentsService from "../../services/appointments-service";
import { convertDateToString, formatToDayMonthYearDate, monthName } from "../../utils/date-converter";
import TextInput from "../../components/TextInput/TextInput";
import DefaultModal from "../../components/DefaultModal/DefaultModal";
import DefaultDropdown from "../../components/DefaultDropdown/DefaultDropdown";
import nutritionistService from "../../services/nutritionist-service";
import NotificationBar from "../../components/NotificationBar/NotificationBar";
import NumericPagination from "../../components/NumericPagination/NumericPagination";

const AgendaDropdown = ({ open, setOpen, options }) => {
  return (
    <Dropdown
      show={open}
      onToggle={() => setOpen(prevState => !prevState)}
    >
      <Dropdown.Menu bsPrefix="dropdown-menu agenda-dropdown">
        {options?.map((item, index) => (
          <Dropdown.Item
            key={index}
            onClick={item.onClick}
            className={`agenda-dropdown__item ${item.className || ''}`}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);
  const [appointmentsResponse, setAppointmentsResponse] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [currentAppointmentsPage, setCurrentAppointmentsPage] = useState(0);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("YEAR");
  const [openAgendaDropdown, setOpenAgendaDropdown] = useState(false);
  const [showManualBookingModal, setShowManualBookingModal] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState(undefined);
  const [dayAvailability, setDayAvailability] = useState();
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState();
  const [booking, setBooking] = useState({});
  const [notificationBar, setNotificationBar] = useState(null);
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [selectedPeriods, setSelectedPeriods] = useState([]);
  const [nextMonthAvailability, setNextMonthAvailability] = useState([]);

  const [showManageAvailabilityModal, setShowManageAvailabilityModal] = useState(false);

  const { setSelectedScreen, isLoading, setIsLoading } = useContext(GlobalContext);

  const appointmentTypeOptions = [
    {
      name: 'Consulta Presencial',
      value: 'ON_SITE'
    },
    {
      name: 'Consulta Remota',
      value: 'ONLINE'
    }
  ]

  const selectableDays = [
    {
      name: "SUNDAY",
      title: "Dom",
    },
    {
      name: "MONDAY",
      title: "Seg",
    },
    {
      name: "TUESDAY",
      title: "Ter",
    },
    {
      name: "WEDNESDAY",
      title: "Qua",
    },
    {
      name: "THURSDAY",
      title: "Qui",
    },
    {
      name: "FRIDAY",
      title: "Sex",
    },
    {
      name: "SATURDAY",
      title: "Sáb",
    },
  ]

  const getFromAndToDatesFromSelectedTimePeriod = useCallback(() => {
    const today = new Date();
    let from = new Date();
    let to = new Date();
    to.setFullYear(today.getFullYear() + 1);

    switch (selectedTimePeriod) {
      case "YEAR":
        from.setFullYear(today.getFullYear() - 1);
        break;
      case "MONTH":
        from.setMonth(today.getMonth() - 1);
        break;
      case "WEEK":
        from.setDate(today.getDate() - 7);
        break;
      default:
        break;
    }

    return { from: convertDateToString(from), to: convertDateToString(to) };
  }, [selectedTimePeriod]);

  const fetchNextMonthAvailability = useCallback(async () => {
    const nextMonth = new Date();
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    const currentUser = await loginService.getCurrentUser();

    const availability = await nutritionistService.getNutritionistAvailability(
      currentUser.id,
      convertDateToString(new Date()),
      convertDateToString(nextMonth)
    );

    setNextMonthAvailability(availability);
  }, [])

  const fetchAvailabilities = useCallback(async (date) => {
    setIsLoading(true);
    try {
      const availability = await nutritionistService.getNutritionistAvailability(currentUser?.id, date, date);
      setDayAvailability(availability);
    } catch (error) {
      console.log("Erro ao buscar disponibilidade", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentUser])

  const fetchCurrentUser = useCallback(async () => {
    const nutritionist = await loginService.getCurrentUser();
    setCurrentUser(nutritionist);
  }, []);

  const updateWeekAvailability = useCallback(async () => {
    const nutritionist = await loginService.getCurrentUser();

    const weekAvailability = {
      days: selectedWeekdays.map(day => {
        return {
          day_of_week: day,
          availability: selectedPeriods,
        }
      }),
    };

    try {
      setIsLoading(true);
      await nutritionistService.updateWeekAvailability(nutritionist?.id, weekAvailability);
      setNotificationBar({ title: "Disponibilidade semanal atualizada com sucesso!", type: "success" });
      setTimeout(() => {
        setShowManageAvailabilityModal(false);
      }, 200);
    } catch (error) {
      console.log("Erro ao atualizar disponibilidade da semana", error);
      setNotificationBar({ title: "Ocorreu um erro ao atualizar a disponibilidade semanal. Tente novamente mais tarde.", type: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [selectedPeriods, selectedWeekdays]);

  const fetchWeekAvailability = useCallback(async () => {
    const nutritionist = await loginService.getCurrentUser();

    try {
      setIsLoading(true);
      const weekAvailability = await nutritionistService.getWeekAvailability(nutritionist?.id);
      // setWeekAvailability(weekAvailability);
      setSelectedWeekdays(weekAvailability.map(day => day.day_of_week));

      setSelectedPeriods(weekAvailability.length > 0 ? weekAvailability[0].availability : [{ from: undefined, to: undefined }]);

    } catch (error) {
      console.log("Erro ao buscar disponibilidade da semana", error);
      setNotificationBar({ title: "Ocorreu um erro ao buscar a disponibilidade semanal. Tente novamente mais tarde.", type: "error" });
    } finally {
      setIsLoading(false);
    }
  }, [currentUser]);

  const fetchAppointments = useCallback(async (page) => {
    const { from, to } = getFromAndToDatesFromSelectedTimePeriod();
    const nutritionist = await loginService.getCurrentUser();

    try {
      setIsLoading(true);
      const appointmentsResponse = await appointmentsService.getNutritionistAppointments(nutritionist?.id, from, to, page ?? currentAppointmentsPage, 8);
      setAppointments(appointmentsResponse.content);
      setCurrentAppointmentsPage(page + 1);
      setAppointmentsResponse(appointmentsResponse);
    } catch (error) {
      console.log("Erro ao buscar consultas", error);
    } finally {
      setIsLoading(false);
    }
  }, [getFromAndToDatesFromSelectedTimePeriod]);

  const formatAppointmentDateTime = useCallback((dateTime) => {
    const date = new Date(dateTime);
    return `${date.getDate()} de ${monthName[date.getMonth() + 1]} ${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
  }, []);

  const fetchData = useCallback(async () => {
    await fetchCurrentUser();
    await fetchAppointments(0);
    await fetchPatients('');
    await fetchWeekAvailability();
  }, [fetchCurrentUser, fetchAppointments]);

  const createAppointment = useCallback(async (appointment) => {
    const formattedDate = appointment.date.split("/").reverse().join("-");
    const appointmentDateTime = `${formattedDate}T${appointment.time}`;

    const appointmentRequest = {
      ...appointment,
      nutritionist_id: currentUser?.id,
      patient_id: selectedPatient?.id,
      date_time: appointmentDateTime,
    }

    try {
      setIsLoading(true);

      await appointmentsService.createAppointment(appointmentRequest);
      setNotificationBar({ title: "Consulta agendada com sucesso!", type: "success" });

      await fetchAppointments(0);
    } catch (error) {
      console.log("Erro ao criar consulta", error);
      setNotificationBar({ title: "Ocorreu um erro ao agendar a consulta. Tente novamente mais tarde.", type: "error" });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setShowManualBookingModal(false);
      }, 200);
    }
  }, [selectedPatient, currentUser, fetchAppointments]);

  const fetchPatients = useCallback(async (name) => {
    const currentUser = await loginService.getCurrentUser();
    try {
      const patients = await nutritionistService.getPatients(currentUser?.id, 0, 4, name);
      setPatients(patients.content);
    } catch (error) {
      console.log("Erro ao buscar pacientes", error);
    }
  }, [])

  const columnsWidth = {
    PATIENT: 2.5,
    TYPE: 2.5,
    STATUS: 2.5,
    DATE: 3,
    MORE: 0.6,
  }

  const agendaOptions = [
    {
      title: "Gerenciar disponibilidade",
      onClick: async () => {
        await fetchWeekAvailability();
        setShowManageAvailabilityModal(true);
      },
    },
    {
      title: "Agendar manualmente",
      onClick: () => {
        setShowManualBookingModal(true);
      }
    },
  ]

  const formatTime = useCallback((time) => {
    if (!time || time.length < 5) {
      return time;
    }

    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}`;
  }, []);

  useEffect(() => {
    document.title = "Appointments";
    setSelectedScreen("appointments");

    Promise.resolve(fetchData());
  }, [fetchData]);

  useEffect(() => {
    fetchAppointments(0);
    fetchNextMonthAvailability();
  }, [selectedTimePeriod]);

  const calcLastElementWidth = () => {
    const restOfAvailableTimes = dayAvailability.days[0].available_hours.length % 4;
    console.log('restOfAvailableTimes', restOfAvailableTimes);
    if (restOfAvailableTimes === 1) {
      return '100%';
    } else if (restOfAvailableTimes === 2) {
      return `${87.66 * 3}px`;
    } else if (restOfAvailableTimes === 3) {
      return `${87 * 2}px`;
    }

    return undefined;
  }

  const bookingIsFilled = () => {
    const patientWasSelected = selectedPatient !== undefined;

    const dateIsFilled = booking.date?.length === 10;
    const timeIsFilled = booking.time !== undefined;

    const typeIsFilled = booking.type !== undefined;

    return patientWasSelected && dateIsFilled && timeIsFilled && typeIsFilled;
  }

  const availabilityIsFilled = () => {
    const weekdaysAreFilled = selectedWeekdays.length > 0;
    const periodsAreFilled = selectedPeriods.length > 0;

    const periodsAreValid = selectedPeriods.every(period => {
      if (!period.from || !period.to || period.from.length < 5 || period.to.length < 5) {
        return false;
      }

      const [fromHours, fromMinutes] = period.from.split(":");
      const [toHours, toMinutes] = period.to.split(":");
      const fromNumber = Number(`${fromHours}${fromMinutes}`);
      const toNumber = Number(`${toHours}${toMinutes}`);

      return fromNumber < toNumber;
    });

    return weekdaysAreFilled && periodsAreFilled && periodsAreValid;
  }

  return (
    <>
      <div className="nutritionist">
        <div className="nutritionist__content">
          <div className="nutritionist__content__header">
            <div className="nutritionist__content__header__title">
              <h1>{appointmentsResponse?.total_elements || 0} Consultas Agendadas</h1>
            </div>
            <div className="appointments__header__actions">
              <LightMenu
                items={[
                  {
                    code: "YEAR",
                    title: "12 meses",
                    onClick: () => setSelectedTimePeriod("YEAR"),
                  },
                  {
                    code: "MONTH",
                    title: "30 dias",
                    onClick: () => setSelectedTimePeriod("MONTH"),
                  },
                  {
                    code: "WEEK",
                    title: "7 dias",
                    onClick: () => setSelectedTimePeriod("WEEK"),
                  },
                ]}
                selectedCode={selectedTimePeriod}
              />
              <div className="appointments__header__actions__agenda">
                <DefaultButton onClick={() => setOpenAgendaDropdown(!openAgendaDropdown)}>
                  <img src={CalendarIcon} alt="Calendar Icon" />
                  <p>Agenda</p>
                </DefaultButton>
                <AgendaDropdown open={openAgendaDropdown} setOpen={setOpenAgendaDropdown} options={agendaOptions} />
              </div>
            </div>
          </div>
          {appointmentsResponse?.total_elements > 0 && (
            <div className="default-table appointments__table">
              <div>
                <div className="table__header">
                  <ColumnHeader title="Paciente" column="PATIENT" columnsWidth={columnsWidth} />
                  <ColumnHeader title="Tipo de Consulta" column="TYPE" columnsWidth={columnsWidth} />
                  <ColumnHeader title="Status" column="STATUS" columnsWidth={columnsWidth} />
                  <ColumnHeader title="Data e hora" column="DATE" columnsWidth={columnsWidth} />
                  <ColumnHeader title="" column="MORE" columnsWidth={columnsWidth} />
                </div>
                <div className="table__content">
                  {appointments?.map((appointment) => (
                    <div className="table__row">
                      <ColumnValue column="PATIENT" columnsWidth={columnsWidth}>
                        <div className="appointments__column__patient">
                          <div className="appointments__column__patient-icon">
                            <img src={appointment.patient.photo_url} alt="Patient icon" />
                          </div>
                          <p>{appointment.patient.name}</p>
                        </div>
                      </ColumnValue>
                      <ColumnValue column="TYPE" columnsWidth={columnsWidth}>
                        {appointment.type === "ON_SITE" ? "Presencial" : "Online"}
                      </ColumnValue>
                      <ColumnValue column="STATUS" columnsWidth={columnsWidth}>
                        {appointment.status === "CONFIRMED" && <StatusTag title="Confirmado" color="green" />}
                        {appointment.status === "CANCELED" && <StatusTag title="Cancelado" color="red" />}
                      </ColumnValue>
                      <ColumnValue column="DATE" columnsWidth={columnsWidth}>
                        {formatAppointmentDateTime(appointment.start_date_time)}
                      </ColumnValue>
                      <ColumnValue column="MORE" columnsWidth={columnsWidth} className="appointments__chevron">
                        <div className="home__nutritionist__users__item__actions__edit">
                          <Button
                            onClick={() =>
                              (window.location.href = `/appointments/${appointment.id}`)
                            }
                          >
                            <img src={ChevronRightIcon} alt="Right Arrow" />
                          </Button>
                        </div>
                      </ColumnValue>
                    </div>
                  ))}
                </div>
              </div>
              <DefaultTableFooter>
                <NumericPagination
                  currentPage={currentAppointmentsPage === 0 ? 1 : Number(currentAppointmentsPage)}
                  totalPages={appointmentsResponse.total_pages}
                  onSelectPage={(page) => {
                    fetchAppointments(page);
                  }}
                />
              </DefaultTableFooter>
            </div>
          )}
          {appointmentsResponse?.total_elements === 0 && (
            <div className="appointments__empty">
              <div className="appointments__empty__icon">
                <img src={NoteTextIcon} alt="Note Text Icon" />
              </div>
              <div className="appointments__empty__title">
                <h2>Sem Consultas Agendadas</h2>
              </div>
              <div className="appointments__empty__description">
                <p>Ainda não há consultas agendadas. Comece a agendar agora e organize sua agenda de forma prática e rápida.</p>
              </div>
              <div className="appointments__empty__button">
                <DefaultButton
                  title="Agendar manualmente"
                  type="primary"
                  onClick={() => setShowManualBookingModal(true)}
                  size="small"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <DefaultModal
        title="Agendar manualmente"
        showModal={showManualBookingModal}
        onClose={() => setShowManualBookingModal(false)}
        className="manual-booking-modal"
      >
        <div className="manual-booking-modal__content">
          <div className="manual-booking-modal__description">
            <p>Digite as informações da consulta para agendar manualmente</p>
          </div>
          <div className="manual-booking-modal__form">
            <DefaultDropdown
              placeholder="Paciente"
              value={selectedPatient?.name}
              options={patients.map((patient) => patient.name)}
              onSelect={(patientName) => {
                const patientFound = patients.find((patient) => patient.name === patientName);
                setSelectedPatient(patientFound);
              }}
              searchPlaceholder="Digite o nome do paciente"
              onSearch={(name) => fetchPatients(name)}
            />
            <DefaultDropdown
              placeholder="Tipo da consulta"
              value={selectedDropdownOption?.name}
              options={appointmentTypeOptions.map((option) => option.name)}
              onSelect={(optionName) => {
                const optionFound = appointmentTypeOptions.find((option) => option.name === optionName);
                setSelectedDropdownOption(optionFound);
                setBooking({ ...booking, type: optionFound.value });
              }}
              className="manual-booking-modal__dropdown"
            />

            <DefaultDropdown
              placeholder="Selecione a data"
              options={nextMonthAvailability?.days?.filter((day) => day.available_hours?.length > 0).map((availability) => {
                return {
                  value: availability.date,
                  label: formatToDayMonthYearDate(availability.date),
                }
              }) ?? []}
              value={booking?.date ? formatToDayMonthYearDate(booking.date) : undefined}
              onSelect={(date) => {
                setBooking({ ...booking, date });
                fetchAvailabilities(date);
              }}
              className="manual-booking-modal__dropdown"
            />
          </div>
          <div className="manual-booking-modal__availabilities">
            {dayAvailability?.days?.length > 0 && dayAvailability?.days[0]?.available_hours?.length > 0 && (
              <>
                <p className="manual-booking-modal__availabilities__title">Horários disponíveis</p>
                <div className="manual-booking-modal__availabilities__content">
                  {dayAvailability?.days[0]?.available_hours?.map((availableTime, index) => (
                    <div
                      className="manual-booking-modal__availability__wrapper"
                      style={{ width: (index === dayAvailability.days[0].available_hours.length - 1 ? calcLastElementWidth() : undefined) }}
                    >
                      <a
                        href="javascript:;"
                        className={`manual-booking-modal__availability ${booking.time === `${availableTime}` ? "manual-booking-modal__availability--selected" : ""}`}
                        onClick={() => setBooking({ ...booking, time: availableTime })}
                      >
                        <p>{`${availableTime.split(":")[0]}:${availableTime.split(":")[1]}`}</p>
                      </a>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="manual-booking-modal__actions">
            <div className="manual-booking-modal__button">
              <DefaultButton
                title="Voltar"
                type="secondary"
                onClick={() => setShowManualBookingModal(false)}
                size="small"
              />
            </div>
            <div className="manual-booking-modal__button">
              <DefaultButton
                title="Agendar"
                type="primary"
                onClick={() => {
                  createAppointment(booking);
                }}
                size="small"
                disabled={!bookingIsFilled()}
              />
            </div>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal
        title="Gerenciar sua Disponibilidade"
        showModal={showManageAvailabilityModal}
        onClose={() => setShowManageAvailabilityModal(false)}
        className="manage-availability-modal"
      >
        <div className="manage-availability-modal__content">
          <div className="manage-availability-modal__description">
            <p>Marque os períodos de disponibilidade para que os pacientes possam agendar apenas quando você estiver disponível.</p>
          </div>
          <div className="manage-availability-modal__week">
            <div className="manage-availability-modal__week__title">
              <p>Escolha os dias que você pode atender</p>
            </div>
            <div className="manage-availability-modal__days">
              {selectableDays.map(day => (
                <a
                  key={day.name}
                  href="javascript:;"
                  className={`manage-availability-modal__day ${selectedWeekdays?.find((weekDay) => weekDay === day.name) ? "manage-availability-modal__day--selected" : ""}`}
                  onClick={() => {
                    setSelectedWeekdays(weekdays => {
                      if (weekdays.find((weekDay) => weekDay === day.name)) {
                        return weekdays.filter((weekDay) => weekDay !== day.name);
                      }

                      return [...weekdays, day.name];
                    });
                  }}
                >
                  <p>{day.title}</p>
                </a>
              ))}
            </div>
          </div>
          <div className="manage-availability-modal__periods">
            <div className="manage-availability-modal__periods__title">
              <p>Escolha os horários</p>
              <div className="manage-availability-modal__periods__title__actions">
                <a
                  href="javascript:;"
                  className="manage-availability-modal__periods__title__action"
                  onClick={() => {
                    if (selectedPeriods?.length > 2) {
                      return;
                    }

                    setSelectedPeriods(periods => {
                      return [...periods, { from: undefined, to: undefined }];
                    });
                  }}
                >+</a>
                <a
                  href="javascript:;"
                  className="manage-availability-modal__periods__title__action"
                  onClick={() => {
                    if (selectedPeriods?.length === 1) {
                      return;
                    }

                    setSelectedPeriods(periods => {
                      return periods.slice(0, periods.length - 1);
                    });
                  }}
                >-</a>
              </div>
            </div>
            <div className="manage-availability-modal__periods__content">
              {selectedPeriods?.map((period, index) => (
                <div className="manage-availability-modal__period">
                  <TextInput
                    type="time"
                    placeholder="00:00"
                    value={formatTime(period.from)}
                    style="manage-availability-modal__input"
                    onChangeText={(value) => {
                      setSelectedPeriods(periods => {
                        return periods.map((currentPeriod, currentIndex) => {
                          if (currentIndex === index) {
                            return { ...currentPeriod, from: value };
                          }

                          return currentPeriod;
                        });
                      });
                    }}
                  />
                  <p className="manage-availability-modal__period__divisor">Até</p>
                  <TextInput
                    type="time"
                    placeholder="00:00"
                    value={formatTime(period.to)}
                    onChangeText={(value) => {
                      // const currentAvailability = weekAvailability[0].availability;
                      // currentAvailability[index] = { from: period.from, to: value };

                      // setWeekAvailability(weekAvailability => {
                      //   return weekAvailability.map(day => {
                      //     return { ...day, availability: currentAvailability };
                      //   });
                      // });

                      setSelectedPeriods(periods => {
                        return periods.map((currentPeriod, currentIndex) => {
                          if (currentIndex === index) {
                            return { ...currentPeriod, to: value };
                          }

                          return currentPeriod;
                        });
                      });
                    }}
                    style="manage-availability-modal__input"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="manage-availability-modal__actions">
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Voltar"
                type="secondary"
                onClick={() => setShowManageAvailabilityModal(false)}
                size="small"
              />
            </div>
            <div className="manage-availability-modal__button">
              <DefaultButton
                title="Salvar"
                type="primary"
                onClick={() => updateWeekAvailability()}
                size="small"
                disabled={!availabilityIsFilled() || isLoading}
              />
            </div>
          </div>
        </div>
      </DefaultModal>
      <NotificationBar
        show={notificationBar?.title}
        title={notificationBar?.title}
        durationInSeconds={4}
        onClose={() => setNotificationBar(null)}
      />
    </>
  );
};

export default Appointments;

Appointments.propTypes = {};
